<style scoped>
.content {
  border-bottom: 1px solid var(--borderColor);
  position: relative;
}

.mm-searchTitle {
  background: rgba(212, 36, 34, 1);
  width: 100%;
  display: flex;
  border-bottom: 1px solid #ccc;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  position: relative;
}
.mm-return {
  width: 10%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
}
.mm-search {
  background-color: #f5f5f5;
  height: 30px;
  border-radius: 5px;
  line-height: 30px;
  position: relative;
  width: 100%;
  display: flex;
}

.mm-cancle {
  width: 15%;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
input:focus {
  outline: none;
}
.mm-search input {
  background: rgba(218, 79, 78, 1);
  width: 100%;
  border: none;
  height: 30px;
  border-radius: 4px;
  padding: 8px 20px;
  color: rgba(243, 183, 180, 1);
  display: flex;
  text-align: left;
  outline: none;
}

/* WebKit browsers */
input::-webkit-input-placeholder {
  color: rgba(243, 183, 180, 1);
  font-size: 14px;
}
.selectContent {
  width: 33%;
}
.mm-detailCon {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  /* position: fixed; */
  /* left:0;
  top:0; */
  background-color: #fff;
}
</style>
<template>
  <div class="mm-detailCon">
    <div class="searchContainers">
      <div class="mm-searchTitle">
        <div class="mm-return" @click="goMain">
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </div>
        <div class="mm-search">
          <input
            type="text"
            id="content"
            placeholder="请输入关键字"
            v-model="searchContent"
          />
          <i
            class="fa fa-times-circle"
            style="color: white; position: absolute; right: 8px; top: 8px"
            v-show="close"
            @click="cancelContent()"
          ></i>
        </div>
        <div class="mm-cancle" @click="search()">搜索</div>
      </div>
      <div style="margin-top: 10px; background: rgba(248 249 255)">
        <live-list-cmp :liveList="searchlist"></live-list-cmp>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable  */
import liveListCmp from "../widgets/rnContentList.vue";

export default {
  name: "index",
  data() {
    return {
      searchContent: "", //输入的搜索内容
      initContent: true, //默认显示感兴趣的内容
      close: false, //清楚按钮
      historyContent: [], //历史搜索的内容
      showHistory: true, //是否显示搜索记录
      loadMoreShow: false,
      isLoadMore: true,
      searchlist: [],

      liveList: [
        {
          title: "最美广铁人宣传发布暨集中宣讲活动启动仪式",
          //  title: '广铁融媒体体验会',
          time: "2021.3.16 10:30-12:00",
          liveStatus: "4",
          authorList: [
            {
              author: "党委宣传部",
              avatar: "/img/logo.jpeg",
            },
          ],

          bookmarkColor: "rgb(233,106,161)",
        },
        {
          title: "空闲",
          time: "",
          liveStatus: "3",
          authorList: [],

          bookmarkColor: "rgb(76,204,148)",
        },

        {
          title: "空闲",
          time: "",
          liveStatus: "3",
          authorList: [],

          bookmarkColor: "rgb(159,144,241)",
        },

        {
          title: "空闲",
          time: "",
          liveStatus: "3",
          authorList: [],
          bookmarkColor: "rgb(255,196,114)",
        },
      ],
    };
  },
  watch: {
    searchContent: function(val) {
      if (val) {
        this.close = true;
        this.search();
      } else {
        this.close = false;
      }
    },
  },
  methods: {
    // Clear the contents of the input box
    cancelContent() {
      this.showHistory = true; // Show History Search Record
      this.initContent = true; // Show Contents of interestt
      var inputCon = document.getElementById("content");
      inputCon.value = "";
      this.searchContent = "";
    },
    // Clear the history search record
    cancelHistory() {
      localStorage.removeItem("historyContent");
      this.historyContent = [];
    },
    // Find content based on keywords entered
    search() {
      if (this.searchContent) {
        this.searchlist = [];
        const reg = new RegExp(this.searchContent); //正则表达式
        for (const iterator of this.liveList) {
          if (iterator.title.match(reg)) {
            this.searchlist.push(iterator);
          }
        }
      }
    },
    // back to main
    goMain() {
      this.$router.back(-1);
    },
  },
  components: { liveListCmp },
  created() {
    if (this.$store.state && this.$store.state.column) {
      this.liveList = this.$store.state.column;
    }
  },
  beforeCreate() {
    document.title = "直播列表";
  },
};
</script>
